var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'menu-section',
    ("px-" + _vm.sectionPadding),
    { subsection: _vm.isSubsection },
    { isDirty: _vm.isDirty },
    _vm.entity.section_type ? ("type--" + (_vm.entity.section_type)) : '',
    { callout: _vm.entity.is_callout },
    { 'has-title': _vm.entity.title } ],on:{"mouseover":function($event){$event.stopPropagation();_vm.isHovering = true},"mouseleave":function($event){$event.stopPropagation();_vm.isHovering = false}}},[_vm._t("default",[(_vm.entity.section_type !== 'hide_title')?_c('div',{staticClass:"menu-section--top mb-3"},[(_vm.entity.title)?_c('BaseEditable',{ref:"title",staticClass:"menu-section--title d-inline-block motor--font",attrs:{"tag":"span.white--text.black.pl-1.pr-2","content":_vm.original.title,"mods":_vm.myMods.title},on:{"update":function($event){return _vm.updateSectionAction('title', $event)},"dirtyUpdate":_vm.adjustDirtScore}}):_vm._e(),(_vm.entity.section_subtitle_note)?_c('div',{staticClass:"menu-section--subtitle"},[_c('BaseEditable',{staticClass:"montserrat--font",attrs:{"tag":"span","content":_vm.original.section_subtitle_note,"mods":_vm.myMods.section_subtitle_note},on:{"update":function($event){return _vm.updateSectionAction('section_subtitle_note', $event, _vm.entity.id)},"dirtyUpdate":_vm.adjustDirtScore}})],1):_vm._e()],1):_vm._e(),_c('div',{class:[
        'menu-section--items',
        { 'text-xs-center': _vm.entity.section_type === 'sides' } ]},[(!_vm.itemIds.length && _vm.isShowingEditUI)?_c('BaseButton',{attrs:{"md-icon":"add","text-color":"green","color":"green","small":"","round":"","outline":""},on:{"click":function($event){return _vm.stageNewItem({ sectionId: _vm.entity.id })}}},[_vm._v("Add an Item")]):_c('BaseMenuComponent',{attrs:{"name":"MenuSectionItems","item-ids":_vm.itemIds,"section":_vm.entity},on:{"addItem":function($event){return _vm.addItemAction({ sectionId: _vm.entity.id, index: $event })},"deleteSectionItem":function($event){return _vm.removeItemAction({ sectionId: _vm.entity.id, itemId: $event.id })},"itemUpdated":function($event){return _vm.updateItemAction(_vm.entity.id, $event)},"dragEvent":_vm.dragEventAction}})],1),(_vm.subsections.length)?_c('div',{staticClass:"menu-section--sub-menus"},_vm._l((_vm.subsections),function(subsection){return _c('WithMenuSection',{key:subsection.id,attrs:{"id":subsection.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isSubsectionLoading = ref.isSectionLoading;
        var theSubsection = ref.section;
        var subsectionItemMods = ref.itemMods;
return [_c(subsection.is_callout ? 'MenuSectionCallout' : 'MenuSection',{tag:"BaseMenuComponent",attrs:{"item-mods":subsectionItemMods,"is-subsection":true,"entity":theSubsection},on:{"updateTitle":function($event){return _vm.updateSectionAction('title', $event, theSubsection.id)},"updateSubtitle":function($event){return _vm.updateSectionAction(
              'section_subtitle_note',
              $event,
              theSubsection.id
            )},"dirtyUpdate":_vm.adjustDirtScore}})]}}],null,true)})}),1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }